import {
    RaceStatus,
    RaceStatusClass,
    RaceType,
    StartType,
    MeetingDomain,
    ToteGateway,
    BOG_COUNTRIES,
} from '@obr-core/config/race'
import { BetCategory, BetSpecialType, BetType } from '@obr-core/config/betting'
import { Currency } from '@obr-core/config/settings'
import { FixedOddsStatus } from '@obr-core/config/race'

/**
 * Extend API Runners with H2H Tags list
 * @param data Runner data from API response
 */
export function extendRunnersH2HTags(
    data: OBR.Race.Runner[]
): OBR.Race.Runner[] {
    const ids: string[] = []

    for (let i = 0, dl = data.length; i < dl; i += 1) {
        const runner = data[i]

        for (let j = 0, l = runner.h2h_races.length; j < l; j += 1) {
            if (!ids.includes(runner.h2h_races[j])) {
                ids.push(runner.h2h_races[j])
            }
        }
    }

    return data.map((runner) => {
        const h2hTags = []

        for (let j = 0, l = runner.h2h_races.length; j < l; j += 1) {
            h2hTags.push(`${ids.indexOf(runner.h2h_races[j]) + 1}`)
        }

        return { ...runner, _h2h_tags: h2hTags.sort() }
    })
}

/**
 *
 * Extend socket race
 */
export function extendRaceByRaceCardSocket(
    oldRace: OBR.Race.Race,
    socketRace: OBR.Race.SocketRaceCardResponse
): OBR.Race.Race {
    // update race
    if (socketRace.race) {
        oldRace.status = socketRace.race.raceStatus
        oldRace.fixed_odds_status = socketRace.race.fixedOddsStatus
        oldRace.non_runner_no_bet = socketRace.race.nonRunnerNoBet
        oldRace.post_time = socketRace.race.postTime

        // Markets
        const updatedBetTypes: OBR.Race.BetType[] = []

        if (socketRace.betTypes?.normal?.[BetCategory.BOOKIE]) {
            Object.keys(socketRace.betTypes.normal[BetCategory.BOOKIE]).forEach(
                (betType) => {
                    updatedBetTypes.push({
                        bet_type: betType as OBR.Betting.BetType,
                        categories: [BetCategory.BOOKIE],
                    })
                }
            )
        }

        if (socketRace.betTypes?.normal?.[BetCategory.FIXED]) {
            Object.keys(socketRace.betTypes.normal[BetCategory.FIXED]).forEach(
                (betType) => {
                    const isBetTypeAlreadySet = updatedBetTypes.find(
                        (item) => betType === item.bet_type
                    )
                    if (isBetTypeAlreadySet) {
                        isBetTypeAlreadySet.categories.push(BetCategory.FIXED)
                    } else {
                        updatedBetTypes.push({
                            bet_type: betType as OBR.Betting.BetType,
                            categories: [BetCategory.FIXED],
                        })
                    }
                }
            )
        }

        if (socketRace.betTypes?.normal?.[BetCategory.TOTE]) {
            Object.keys(socketRace.betTypes.normal[BetCategory.TOTE]).forEach(
                (betType) => {
                    const isBetTypeAlreadySet = updatedBetTypes.find(
                        (item) => betType === item.bet_type
                    )
                    if (isBetTypeAlreadySet) {
                        isBetTypeAlreadySet.categories.push(BetCategory.TOTE)
                    } else {
                        updatedBetTypes.push({
                            bet_type: betType as OBR.Betting.BetType,
                            categories: [BetCategory.TOTE],
                        })
                    }
                }
            )
        }

        // Update bet types only when there are updated bet types.
        // Otherwise, markets will be emptied on racecard page
        if (updatedBetTypes.length) {
            oldRace.bet_types = updatedBetTypes
        }
    }
    // update runner
    if (socketRace.runners?.length) {
        socketRace.runners.forEach((runner) => {
            const findRunner = oldRace.runners?.find(
                (run) => run.id === `${runner.idRunner}`
            )
            if (findRunner) {
                findRunner.jockey_firstname = runner.jockey.firstName || ''
                findRunner.jockey_lastname = runner.jockey.lastName || ''
                findRunner.scratched = runner.scratched
                runner?.odds?.FXP && (findRunner.odds_fxp = runner.odds.FXP)
                runner?.odds?.FXW && (findRunner.odds_fxw = runner.odds.FXW)
                runner?.odds?.PRC && (findRunner.odds_prc = runner.odds.PRC)

                // if results are sent populate runners with their positions
                if (socketRace?.result?.positions?.length) {
                    socketRace.result.positions.forEach((position) => {
                        if (position.idRunner === runner.idRunner) {
                            findRunner.final_position = position.position
                        }
                    })
                }
            }
        })
    }
    // update sibilling
    if (socketRace.siblingRaces && socketRace.siblingRaces?.races?.length) {
        oldRace.sibling_races?.forEach((race) => {
            const findRace = socketRace.siblingRaces?.races?.find(
                (sibilRace) => `${sibilRace.idRace}` === race.id
            )
            if (findRace) {
                race.status = findRace.raceStatus
            } else if (race.id === `${socketRace.race?.idRace}`) {
                race.status = socketRace.race?.raceStatus as OBR.Race.RaceStatus
            }
        })
        // update current race
        const findRace = oldRace.sibling_races?.find(
            (sibilRace) => sibilRace.id === oldRace.id
        )
        findRace && (findRace.status = oldRace.status)
    }
    // update final_position
    if (socketRace.result?.positions?.length) {
        oldRace.final_positions = oldRace.final_positions || []
        socketRace.result.positions.forEach((runner) => {
            const newRunner: OBR.Race.FinalPosition = {
                id_runner: `${runner.idRunner}`,
                name: runner.name,
                position: runner.position,
                program_number: runner.programNumber,
                odds_win:
                    socketRace.result?.odds?.wps.WIN?.[runner.programNumber] ||
                    Number.NEGATIVE_INFINITY,
                odds_plc:
                    socketRace.result?.odds?.wps.PLC?.[runner.programNumber] ||
                    Number.NEGATIVE_INFINITY,
                odds_shw:
                    socketRace.result?.odds?.wps.SHW?.[runner.programNumber] ||
                    Number.NEGATIVE_INFINITY,
            }

            const oldRunner = oldRace.final_positions?.find(
                (run) => `${run.id_runner}` === `${runner.idRunner}`
            )

            if (oldRunner) {
                if (newRunner.odds_win === Number.NEGATIVE_INFINITY) {
                    newRunner.odds_win = oldRunner.odds_win
                }
                if (newRunner.odds_plc === Number.NEGATIVE_INFINITY) {
                    newRunner.odds_plc = oldRunner.odds_plc
                }
                Object.assign(oldRunner, newRunner)
            } else {
                oldRace.final_positions?.push(newRunner)
            }
        })
        //order final position
        oldRace.final_positions?.sort((a, b) => a.position - b.position)
    }
    // update final_odds
    if (socketRace.result?.odds?.other) {
        oldRace.final_odds = []
        socketRace.result.odds.other.forEach((odds) => {
            for (const betType in odds) {
                for (const combOdds in odds[betType]) {
                    oldRace.final_odds?.push({
                        bet_type: betType,
                        odds: parseFloat(combOdds),
                        combination: odds[betType][combOdds],
                    })
                }
            }
        })
    }
    return oldRace
}

export function extendRaceByEventSocket(
    oldRace: OBR.Race.Race,
    socketRace: any
): OBR.Race.Race {
    // update race
    if (socketRace) {
        oldRace.status = socketRace.raceStatus
        oldRace.post_time = socketRace.postTime
    }

    // update runner
    if (socketRace.runners?.length) {
        socketRace.runners.forEach((runner: any) => {
            const findRunner = oldRace.runners?.find(
                (run) => run.id === `${runner.idRunner}`
            )
            if (findRunner) {
                if (typeof runner.scratched === 'boolean') {
                    findRunner.scratched = runner.scratched
                }

                if (runner.winOdds) {
                    findRunner.odds_fxw = runner.winOdds
                }
            }
        })
    }

    return oldRace
}

export const defaultRunner: OBR.Race.Runner = {
    // from every posible runner - only 3 common elements
    id: '',
    name: '',
    odds_fxw: 0,
    blinkers: false,
    age: 0,
    ante_post_markets: false,
    id_subject: '',
    distance: 0,
    distance_front: null,
    distance_winner: null,
    final_position: 0,
    final_position_parent: 0,
    fixed_odds_history: [],
    gender: '',
    h2h_races: [],
    homeland: null,
    id_race: '',
    id_race_parent: null,
    id_runner_parent: '',
    //TODO: Unify jockey_name
    jockey_name: '',
    jockey_firstname: null,
    jockey_lastname: null,
    jockey_weight: null,
    odds_fxp: 0,
    odds_prc: 0,
    opponents: [],
    origin: null,
    past_performance: '',
    pin_sticker: null,
    post_position: null,
    program_number: 0,
    program_number_parent: null,
    reserve: false,
    scratched: false,
    //TODO: Unify Silk Extension
    silk_extension: null,
    silk_id: null,
    silk_path: '',
    stable: false,
    stable_identifier: '',
    //TODO: unify trainer_name
    trainer_name: '',
    trainer_firstname: '',
    trainer_lastname: '',
    weight_allowance: 0,
    weight_addition: 0,
    // what is this?
    _h2h_tags: [],
    special_type: BetSpecialType.NORMAL,
    race_odds: 0,
    dam: '',
    sire: '',
    price_history: [],
    close_up_comments: '',
}

/**
 * parse Runner from betslip socket
 */
export function parseRunnerFromBetslipSocket(
    socketRunner: OBR.Betting.BetslipSocketRunner,
    existRunner?: OBR.Race.Runner
): OBR.Race.Runner {
    const _runner = {
        id: `${socketRunner.idRunner}`,
        odds_fxp: socketRunner.odds?.FXP,
        odds_fxw: socketRunner.odds?.FXW,
        odds_prc: socketRunner.odds?.PRC,
        scratched: socketRunner.scratched,
        reserve: socketRunner.reserve,
        jockey_firstname: socketRunner.jockey?.firstName,
        jockey_lastname: socketRunner.jockey?.lastName,
    }
    return existRunner
        ? { ...existRunner, ..._runner }
        : { ...defaultRunner, ..._runner }
}

/**
 * convert Racestatus in class name color defined in tailwind.css
 * @param raceStatus
 * @returns RaceStatusClass
 */
export function raceStatusToClassColor(
    raceStatus: RaceStatus
): RaceStatusClass {
    switch (raceStatus) {
        case RaceStatus.OPEN:
            return RaceStatusClass.OPEN

        case RaceStatus.STARTED:
        case RaceStatus.TEMPORARY_RESULTS:
        case RaceStatus.REVIEW:
            return RaceStatusClass.GOING

        case RaceStatus.FINAL:
            return RaceStatusClass.FINAL

        case RaceStatus.NULL:
            return RaceStatusClass.NULL

        default:
            return RaceStatusClass.CANCELLED
    }
}

export const defaultRace: OBR.Race.Race = {
    id: '',
    title: '',
    status: RaceStatus.OPEN,
    post_time: 0,
    off_time: 0,
    bet_types: [],
    age_from: 0,
    age_to: 0,
    auto_start: false,
    bookie_preset: true,
    best_odds_guaranteed: false,
    category_letter: '',
    category: '',
    country: '',
    dead_heat: false,
    distance: 0,
    event_title: '',
    favourites: [],
    final_odds: [],
    final_positions: [],
    first_start: 0,
    fixed_odds_status: FixedOddsStatus.NOT_AVAILABLE,
    has_silks: true,
    id_event: '',
    id_event_h2h: '',
    id_event_special_bets: '',
    id_race_parent: '',
    id_track: '',
    is_ante_post: false,
    is_priced_up: false,
    meeting_domain: MeetingDomain.NON_VIRTUAL,
    multiples_bok: false,
    multiples_fxd: false,
    non_runner_no_bet: false,
    num_runners: 0,
    number: 0,
    pick_bet_types: [],
    place_odds_factor: 1,
    places_num: 0,
    preview_has_audio: false,
    preview_provider: '',
    preview_text: '',
    promotions: [],
    purse: 0,
    purse_currency: Currency.EUR,
    rule_4: false,
    rule_4_deductions: [],
    results: [],
    runners: [],
    start_type: StartType.NONE,
    tax_fees_tot: 0,
    tax_fees_bok: 0,
    tax_fees_fxd: 0,
    tote_currency: Currency.EUR,
    track_going: 0,
    track_surface: '',
    type_detail: '',
    sibling_races: [],
    sp_event: false,
    id_event_parent: '',
    event_date: '',
    conditions: '',
    specials_title: '',
    race_type: RaceType.GALLOP,
    special_type: BetSpecialType.NORMAL,
    scratches: [],
    tote_gateway: '' as ToteGateway,
    original_places_num: 0,
    race_number: 0,
}

/**
 * convert race_type in ratingType
 * @param raceType
 * @returns
 */
export function raceType2RatingType(raceType: string) {
    switch (raceType) {
        case 'FLT':
            return 'TRF'
        case 'HRD':
        case 'STC':
            return 'HRD'
        case 'CHS':
        case 'HCH':
        case 'HNT':
        case 'NHF':
            return 'CHS'
        default:
            return ''
    }
}

/**
 * Check if race has status OPEN and if markets arrays does not have only WIN market
 * e.g. used to display race market component on race card
 */
export function showRaceMarkets(
    raceStatus: OBR.Race.RaceStatus | undefined,
    markets: OBR.Betting.Market[]
) {
    return (
        raceStatus &&
        RaceStatus.OPEN === raceStatus &&
        !(markets.length === 1 && markets[0].bet_type === BetType.WIN)
    )
}

export function showBOGLabel(
    isAntepost: boolean,
    raceBOG: boolean,
    userBOG: boolean,
    eventCountry: string
) {
    if (
        !isAntepost &&
        (raceBOG || (userBOG && BOG_COUNTRIES.includes(eventCountry)))
    ) {
        return true
    }
    return false
}
