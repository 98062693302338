/**
 * Betslip Type
 */
export const enum BetslipType {
    STANDARD = 'STD',
    ACCUMULATION = 'ACC',
}

/**
 * Betslip multiples system name
 */
export enum System {
    FOLD = 'FOLD',
    FULL = 'FULL',
    COMB = 'COMB',
}

export interface BetslipMultiplesGenerator {
    system: System
    value: number
    label: SystemLabel
    baseBetsCount: number
    odds?: number
    oddsPlc?: number
    amount: number
}

export enum SystemLabel {
    FOLD_2 = 'label_system_2X',
    FOLD_3 = 'label_system_3X',
    FOLD_4 = 'label_system_4X',
    FOLD_5 = 'label_system_5X',
    FOLD_6 = 'label_system_6X',
    FOLD_7 = 'label_system_7X',
    FOLD_8 = 'label_system_8X',
    COMB_3 = 'label_system_3C',
    COMB_4 = 'label_system_4C',
    COMB_5 = 'label_system_5C',
    COMB_6 = 'label_system_6C',
    COMB_7 = 'label_system_7C',
    COMB_8 = 'label_system_8C',
    FULL_3 = 'label_system_3F',
    FULL_4 = 'label_system_4F',
    FULL_5 = 'label_system_5F',
    FULL_6 = 'label_system_6F',
}

//baseBetsCount
export const baseBetsCount = {
    [SystemLabel.FOLD_2]: 2,
    [SystemLabel.FOLD_3]: 3,
    [SystemLabel.FOLD_4]: 4,
    [SystemLabel.FOLD_5]: 5,
    [SystemLabel.FOLD_6]: 6,
    [SystemLabel.FOLD_7]: 7,
    [SystemLabel.FOLD_8]: 8,
    [SystemLabel.COMB_3]: 3,
    [SystemLabel.COMB_4]: 4,
    [SystemLabel.COMB_5]: 5,
    [SystemLabel.COMB_6]: 6,
    [SystemLabel.COMB_7]: 7,
    [SystemLabel.COMB_8]: 8,
    [SystemLabel.FULL_3]: 3,
    [SystemLabel.FULL_4]: 4,
    [SystemLabel.FULL_5]: 5,
    [SystemLabel.FULL_6]: 6,
} as {
    [key in SystemLabel]: number
}

export const enum RmsError {
    SP_ONLY = 'FAB',
    WIN_ONLY = 'WOL',
    LIMIT = 'NUS',
}

/**
 * Key to get access to betslip
 */
export const BETSLIP_STORAGE_KEY_BETS: OBR.Storage.Key = 'betslip/bets'

/**
 * Key to get access races linked to betslip
 */
export const BETSLIP_STORAGE_KEY_RACES: OBR.Storage.Key = 'betslip/races'

/**
 * Betslip slider actions
 */
export enum BetslipSliderAction {
    REMOVE = 'remove',
    CHANGE_IN_MULTIPLES = 'change_in_multiples',
    CHANGE_CATEGORY = 'change_category',
    INCLUDE_EXCLUDE = 'include_exclude',
}

/**
 * Betslip multiples errors and conflicts
 */
export enum BetslipMultipleSingleErrors {
    TOTE = 'tote_multiples',
    BET_TYPE_MISMATCH = 'bet_type_mismatch',
    NO_MULTIPLES_BOK = 'no_multiples_bok',
    NO_MULTIPLES_FIXED = 'no_multiples_fixed',
    CONFLICT_ANTEPOST_NORMAL = 'conflict_antepost_normal',
    CONFLICT_NORMAL_ANTEPOST = 'conflict_normal_antepost',
    CONFLICT_H2H_NORMAL = 'conflict_h2h_normal',
    CONFLICT_NORMAL_H2H = 'conflict_normal_h2h',
    CONFLICT_SAME_RUNNER = 'conflict_same_runner',
}

/**
 * Max number of races in betslip
 */
export const BETSLIP_MAX_NUMBER_OF_RACES = 8

/**
 * Key to get access to pickbets
 */
export const BETSLIP_STORAGE_KEY_PICKBETS: OBR.Storage.Key = 'betslip/pickbets'

/**
 * Key to get access to pickbets ID
 */
export const BETSLIP_STORAGE_KEY_PICKBETS_ID: OBR.Storage.Key =
    'betslip/activePickBetId'

/**
 * Key to get temp status of betslip (when reload the page we do not want to populate bet slip if user clicked remove all bets before)
 */
export const BETSLIP_STORAGE_KEY_IS_TEMP_STATE: OBR.Storage.Key =
    'betslip/tempState'
