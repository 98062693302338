import { BetslipResource } from '@obr-core/resources/BetslipResource'
import { getItem, setItem } from '@obr-core/lib/storage.manager'
import {
    BETSLIP_STORAGE_KEY_BETS,
    BETSLIP_STORAGE_KEY_RACES,
    BETSLIP_STORAGE_KEY_PICKBETS,
    BETSLIP_STORAGE_KEY_PICKBETS_ID,
} from '@obr-core/config/betslip'

/**
 * Betslip Service
 * Singleton
 */
export class BetslipService {
    private static instance: BetslipService
    private betslipResource: BetslipResource

    private constructor() {
        this.betslipResource = new BetslipResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): BetslipService {
        if (BetslipService.instance === undefined) {
            BetslipService.instance = new BetslipService()
        }
        return BetslipService.instance
    }

    /**
     * get betslip for current user
     */
    public get(): {
        betslip: OBR.Betting.BetslipPayload
        races: OBR.Race.Race[]
    } {
        const bets = getItem(BETSLIP_STORAGE_KEY_BETS)
        const races = getItem(BETSLIP_STORAGE_KEY_RACES)
        const pickBets = getItem(BETSLIP_STORAGE_KEY_PICKBETS)
        const activePickBetId = getItem(BETSLIP_STORAGE_KEY_PICKBETS_ID)

        const result = {
            betslip: bets || {
                bets: [],
                multiples: [],
            },
            races: races || [],
        }

        if (pickBets && activePickBetId) {
            result.betslip.pickBets = pickBets
            result.betslip.activePickBet = activePickBetId
        }

        return result
    }

    public update(betslip: OBR.Betting.BetslipPayload, races: OBR.Race.Race[]) {
        setItem(BETSLIP_STORAGE_KEY_BETS, betslip)
        setItem(BETSLIP_STORAGE_KEY_RACES, races)
    }

    public updateRaces(race: OBR.Race.Race, isPickBet = false) {
        const races =
            (getItem(BETSLIP_STORAGE_KEY_RACES) as OBR.Race.Race[]) || []

        if (
            isPickBet &&
            races.length &&
            races[0].event_title !== race.event_title
        ) {
            setItem(BETSLIP_STORAGE_KEY_RACES, [])
        }

        const raceIndex = races.findIndex((r) => r.id === race.id)

        if (raceIndex !== -1) {
            races[raceIndex] = race
        } else {
            races.push(race)
        }

        setItem(BETSLIP_STORAGE_KEY_RACES, races)
    }

    public async validate(betslip: OBR.Betting.BetslipPayload) {
        return this.betslipResource.validate(betslip)
    }

    public save(
        betslip: Pick<OBR.Store.BetslipState, 'bets' | 'multiples'>,
        isMultiples = false
    ) {
        return this.betslipResource.save(betslip, isMultiples || false)
    }

    public smsResult(publicIds: string[], mobile: string) {
        return this.betslipResource.smsResult(publicIds, mobile)
    }

    public highestCombination(payload: OBR.Betting.HighestCombinationPayload) {
        return this.betslipResource.getHighestCombination(payload)
    }

    public allCombination(payload: OBR.Betting.AllCombinationsPayload) {
        return this.betslipResource.getAllCombination(payload)
    }
}
