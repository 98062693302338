import { defineStore } from 'pinia'

import {
    APP_ERROR_INIT_ERROR,
    APP_ERROR_UNKNOWN_ERROR,
} from '@obr-core/config/app'
import { AppService } from '@obr-core/services/api'
import { SSRService } from '@obr-core/services/SSRService'
import { postMessageService } from '@obr-core/services/PostMessageService'
import { POST_MESSAGE_ID_READY } from '@obr-core/config/post-messages'
import { initThreatMetrix } from '@obr-core/lib/threat-metrix'
import { useI18n } from '@obr-core/store/i18n'
import { useUser } from '@obr-core/store/user'
import { isIframe, isWebComponent } from '@obr-core/helpers/app.helpers'
import { emitter } from '@obr-core/services/EmitterService'
import {
    EMITTER_WEBCOMPONENT_CREATED,
    EMITTER_WEBCOMPONENT_MOUNTED,
} from '@obr-core/config/emitter'
import { Product } from '@obr-core/config/env'

import { initialState } from './state'

const appService = AppService.getInstance()

export const useApp = defineStore('obr-store/app', {
    state: initialState,
    actions: {
        async onInit(token?: string) {
            const userStore = useUser()
            const i18nStore = useI18n()

            if (token) {
                // In future, this will be a string value for JWT token
                userStore.logged_in = !!token
            }

            const ssrBootstrap = SSRService.getInstance().getBootstrapOnce()
            const isSSRLoaded = ssrBootstrap && Object.keys(ssrBootstrap).length

            !isSSRLoaded && this.setLoading(true)
            const startUpCall: Promise<any>[] = [i18nStore.loadBaseLocale()]

            // Call bootstrap in onInit for Million game only as it does not
            // have horse-racing OneBuild configuration yet
            if (import.meta.env.VUE_APP_PRODUCT === Product.MILLION_GAME) {
                startUpCall.push(
                    this.loadBootstrap(isSSRLoaded ? ssrBootstrap : undefined)
                )
            }

            await Promise.all([
                userStore.onInit(),
                Promise.all(startUpCall)
                    .catch(() => this.setError(APP_ERROR_INIT_ERROR))
                    .finally(() => this.setLoading(false)),
            ])

            if (ssrBootstrap?.currency_settings) {
                userStore.onSetBootstrap(ssrBootstrap.currency_settings)
            }

            // Init Threat Metrix for logged in customers
            if (userStore.logged_in && this.tm_session_id) {
                initThreatMetrix(this.tm_session_id)
            }
        },
        onCreated() {
            //
            emitter.emit(EMITTER_WEBCOMPONENT_CREATED)
        },
        onMounted() {
            postMessageService.send(POST_MESSAGE_ID_READY, true)
            emitter.emit(EMITTER_WEBCOMPONENT_MOUNTED)
            this.setMounted(true)
        },
        async loadBootstrap(ssrBootstrap?: OBR.App.BootstrapResponse) {
            const userStore = useUser()
            const bootstrap = ssrBootstrap || (await appService.getBootstrap())

            if (
                isWebComponent() &&
                !isIframe() &&
                !window.__INITIAL_SSR_STATE__
            ) {
                const bodyElement = document.querySelector('body')
                const bootstrapScript = document.createElement('script')
                bootstrapScript.innerHTML = `window.__INITIAL_SSR_STATE__ = {bootstrap:${JSON.stringify(
                    bootstrap
                )}}`
                bodyElement?.appendChild(bootstrapScript)
            }

            userStore.onSetUser({
                currency_settings: bootstrap.currency_settings,
            })
            this.setBootstrap(bootstrap)
            return bootstrap
        },
        setBootstrap(bootstrap: OBR.App.BootstrapResponse) {
            this.exchange_rates = bootstrap.exchange_rates
            this.ip_country = bootstrap.ip_country
            this.tm_session_id = bootstrap.tm_session_id || null
            this.languages = bootstrap.languages
            this.brand_child = bootstrap.child_brand
            this.hide_greyhounds = bootstrap.view_settings.hide_greyhounds
            this.hide_streams = bootstrap.view_settings.hide_streams
            this.hide_virtuals = bootstrap.view_settings.hide_virtuals
            this.use_seo = bootstrap.view_settings.use_seo
            this.hide_tote = bootstrap.view_settings.hide_tote
            this.hide_jackpots = bootstrap.view_settings.hide_jackpots
        },
        setupApp(bootstrap: OBR.App.BootstrapResponse) {
            const userStore = useUser()
            this.setBootstrap(bootstrap)
            userStore.onSetBootstrap(bootstrap.currency_settings)
        },
        setLoading(status: boolean) {
            this.loading = status
        },
        setLoadingOverlay(status: boolean) {
            this.loading_overlay = status
        },
        setMounted(status: boolean) {
            this.mounted = status
        },
        setWebsiteTld(website_tld: OBR.Settings.TLD) {
            this.website_tld = website_tld
        },
        setError(error: Error | string) {
            this.error = true
            this.error_data.push(
                typeof error === 'string'
                    ? error
                    : error.message || APP_ERROR_UNKNOWN_ERROR
            )
        },
        setLeftDrawerVisible(status: boolean) {
            this.left_drawer_visible = status
        },
        setRightDrawerVisible(status: boolean) {
            this.right_drawer_visible = status
        },
        setLeftMenuVisible(status: boolean) {
            this.left_menu_visible = status
        },
        setRedirect(route: OBR.Router.RouteLocationRaw | null) {
            this.redirect = route
        },
        setFocusOnInput(status: boolean) {
            this.is_focus_on_input = status
        },
        setBrand(brand: string) {
            this.brand = brand
        },
    },
    getters: {
        getCountry(state) {
            const userStore = useUser()

            return userStore.logged_in
                ? userStore.address.country
                : state.ip_country
        },
        isRightDrawerVisible(state) {
            return state.right_drawer_visible
        },
    },
})
