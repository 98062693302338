import { BootstrapResponse } from 'types/core/app'
import { useApp } from '@obr-core/store/app'

class AppStoreService {
    isLoading(): boolean {
        return useApp().loading
    }

    /**
     * Checks status of loading_overlay
     */
    isLoadingOverlay(): boolean {
        return useApp().loading_overlay
    }
    ipCountry(): string {
        return useApp().ip_country
    }
    exchangeRates() {
        return useApp().exchange_rates
    }
    websiteTLD() {
        return useApp().website_tld
    }
    brand() {
        return useApp().brand
    }
    brandChild() {
        return useApp().brand_child
    }
    getLanguages(): { [key: string]: string } {
        return useApp().languages
    }
    getChildBrand() {
        return useApp().brand_child
    }
    areGreyhoundsHidden() {
        return useApp().hide_greyhounds
    }
    areStreamsHidden() {
        return useApp().hide_streams
    }
    areVirtualsHidden() {
        return useApp().hide_virtuals
    }
    /* Actions */
    init(token?: string) {
        return useApp().onInit(token)
    }
    created() {
        return useApp().onCreated()
    }
    mounted() {
        return useApp().onMounted()
    }
    /* Getters */
    getCountry() {
        return useApp().getCountry as string
    }
    isRightDrawerVisible() {
        return useApp().isRightDrawerVisible
    }
    redirect() {
        return useApp().redirect
    }
    isTouchScreen() {
        return useApp().is_touch_screeen
    }

    isSEOEnabled() {
        return useApp().use_seo
    }
    areJackpotsHidden() {
        return useApp().hide_jackpots
    }
    areToteHidden() {
        return useApp().hide_tote
    }
    /**
     * Sets app loading overlay status
     */
    setLoadingOverlay(status: boolean) {
        return useApp().setLoadingOverlay(status)
    }
    setRedirect(route: OBR.Router.RouteLocationRaw | null) {
        return useApp().setRedirect(route)
    }
    setBrand(brand: string): void {
        useApp().setBrand(brand)
    }
    setupApp(bootstrap: BootstrapResponse) {
        useApp().setupApp(bootstrap)
    }
}

export const appStoreService = new AppStoreService()
