import { useBetslip } from '@obr-core/store/betslip'
import { useUser } from '@obr-core/store/user'
import {
    userStoreService,
    raceStoreService,
    appStoreService,
} from '@obr-core/services/store'
import { getTimestamp } from '@obr-core/helpers/generic.helpers'
import {
    isEachWay,
    prepareMultiple,
    getBetRunnerId,
    getBetRaceId,
    generateSelectionsForSingleBet,
    getPickBetCount,
    getPickMarks,
    isEachWayAvailable,
    getBetAndIndexByUid,
} from '@obr-core/helpers/betslip.helpers'
import {
    BetType,
    BetSpecialType,
    BetCategory,
    RULES,
} from '@obr-core/config/betting'
import { BetslipSubtab } from '@obr-ui/components/Betslip/config'
import { emitter } from '@obr-core/services/EmitterService'
import { EMITTER_BET_PLACED } from '@obr-core/config/emitter'
import { generateId } from '@obr-ui/utils/utils'
import { usePickBets } from '@obr-ui/components/PickBets/composable'
import { renderAddToBetslipAnimation } from '@obr-ui/components/RaceCard/helpers'
import { exchange } from '@obr-core/modules/BettingEngine/helpers/exchange.helpers'

class BetslipStoreService {
    bets(): OBR.Betting.Bet[] {
        return useBetslip().bets
    }
    tempBets(): OBR.Betting.Bet[] {
        return useBetslip().tempBets
    }
    betsNumber(): number {
        // check if there's pick bet on cart and show pick bets number
        if (useBetslip().activePickBet) {
            const { numBets } = usePickBets()
            return numBets.value
        }
        // case no pick bet on cart check if there's any bet on betslip
        return Object.keys(useBetslip().bets).length
    }
    getBetByUid(Uid: string): OBR.Betting.Bet | undefined {
        return useBetslip().bets.find((bet) => bet.uid === Uid)
    }
    multiples(): OBR.Betting.Multiple[] {
        return useBetslip().multiples
    }
    hasChange() {
        return useBetslip().hasChange
    }
    hasUnmatchedOdds() {
        return useBetslip().hasUnmatchedOdds
    }
    hasCurrencyChanged(): boolean {
        return !!useBetslip().currencyHasChanged
    }
    multipleBetType(): OBR.Betting.BetType {
        return useBetslip().multipleBetType
    }
    isRunnerPressed(
        idRace: string,
        idRunner: string,
        market: OBR.Betting.MarketBetType = BetType.WIN
    ) {
        const bets = useBetslip().bets

        if (bets) {
            return bets.some(
                (bet) =>
                    getBetRaceId(bet) === idRace &&
                    getBetRunnerId(bet) === idRunner &&
                    bet.market === market
            )
        }

        return false
    }
    isBetButtonPressed(
        idRace: string,
        idRunner: string,
        betCategory: OBR.Betting.BetCategory,
        market: OBR.Betting.MarketBetType = BetType.WIN
    ) {
        const bets = useBetslip().bets

        if (bets) {
            return bets.some(
                (bet) =>
                    getBetRaceId(bet) === idRace &&
                    getBetRunnerId(bet) === idRunner &&
                    bet.category === betCategory &&
                    bet.market === market
            )
        }

        return false
    }

    isBetButtonFreeBet(
        idRace: string,
        idRunner: string,
        betCategory: OBR.Betting.BetCategory,
        market: OBR.Betting.MarketBetType = BetType.WIN
    ) {
        const bets = useBetslip().bets

        if (bets) {
            return bets.some(
                (bet) =>
                    getBetRaceId(bet) === idRace &&
                    getBetRunnerId(bet) === idRunner &&
                    bet.category === betCategory &&
                    bet.market === market &&
                    bet.id_freebet
            )
        }

        return false
    }

    /* Actions */
    load() {
        return useBetslip().onLoad()
    }
    save(activeSubtab?: BetslipSubtab): Promise<OBR.Betting.BetslipResponse> {
        return useBetslip()
            .onSave(activeSubtab)
            .then((res: OBR.Betting.BetslipResponse) => {
                if (res.type === 'success') {
                    // update balance
                    userStoreService.getBalance()
                    emitter.emit(EMITTER_BET_PLACED)
                }
                useBetslip().onRmoveAllRmsData()
                useBetslip().onSetHasUnmatchedOdds(false)
                return res
            })
    }

    smsResult(publicIds: string[], mobile: string) {
        return useBetslip().onSmsResult(publicIds, mobile)
    }
    saveRace(race: OBR.Race.Race, isPickBet = false) {
        return useBetslip().onSaveRace(race, isPickBet)
    }
    addBet(bet: OBR.Betting.Bet) {
        return useBetslip().onAddBet(bet)
    }
    removeBet(index: number) {
        return useBetslip().onRemoveBet(index)
    }
    removeBetByUid(uid: string) {
        let index = -1
        this.bets().find((bet, idx) => {
            if (bet.uid === uid) {
                index = idx
                return true
            }
            return false
        })
        return useBetslip().onRemoveBet(index)
    }
    removeAllBet(publicIds?: string[]) {
        useBetslip().onSetHasUnmatchedOdds(false)
        return useBetslip().onRemoveAllBets(publicIds)
    }
    putBetsToTemp(id: NodeJS.Timeout) {
        return useBetslip().onPutBetsToTemp(id)
    }
    putPickBetsToTemp(pickBetId: string, id: NodeJS.Timeout) {
        return useBetslip().onPutPickBetsToTemp(pickBetId, id)
    }
    undoPutBetsToTemp() {
        return useBetslip().onUndoPutBetsToTemp()
    }
    undoPutPickBetsToTemp() {
        return useBetslip().onUndoPutPickBetsToTemp()
    }
    removeBetsByIndex(indexes: number[]) {
        return useBetslip().removeBetsByIndex(indexes)
    }
    updateBet(bet: OBR.Betting.Bet, index: number) {
        return useBetslip().onUpdateBet({ bet, index })
    }
    updateBetMultiples(inMultiples: boolean, uid: string) {
        const { bet, index } = getBetAndIndexByUid(this.bets(), uid)
        if (bet) {
            this.updateBet(
                Object.assign({}, bet, { in_multiples: inMultiples }),
                index
            )
        }
    }

    removeAllFreebetsIdsAndRms() {
        useBetslip().onRemoveAllFreebetsIdsAndRms()
    }
    validateBetslip() {
        useBetslip().onValidateBetslip()
    }
    addRmsLimitToBets(
        isMultiple: boolean,
        betNumber: number,
        idRms: number,
        type: OBR.Betting.RmsError,
        newUnitStake?: number
    ) {
        useBetslip().onSetRmsResponse(
            isMultiple,
            betNumber,
            idRms,
            type,
            newUnitStake
        )
    }

    addSimpleBet(
        idRace: string,
        idRunner: string,
        betCategory: OBR.Betting.BetCategory,
        betType: OBR.Betting.BetType = BetType.WIN,
        betSpecialType: OBR.Betting.BetSpecialType = BetSpecialType.NORMAL
    ) {
        const race = raceStoreService.raceByCache(idRace)
        const runner = raceStoreService.runnerByCache(idRunner)
        if (!race || !runner) {
            return
        }
        let isInMultiple = false

        // Exclude USA TOT bets like WIN/SHOW/PLACE from multiple bets logic
        // even if those bets are possible in multiples - runner does have not TOT category and Type like WIN
        // It will not show up in betslip - That is possible for betsson brasil only
        if (
            [BetType.WIN, BetType.PLACE, BetType.ITA, BetType.TRITA].includes(
                betType
            )
        ) {
            isInMultiple = !betslipStoreService
                .bets()
                .filter((bet) => bet.in_multiples)
                .some((bet) => getBetRunnerId(bet) === idRunner)
        }

        if (!race || !runner) {
            return
        }

        const market = betType
        // check if bet on this runner is already in betslip

        const indexOfBetInBetslip: number = this.bets().findIndex((bet) => {
            const betCategories = [betCategory]

            // if on racecard TOT and BOK normal bet is available we display only BOK button
            // in case BOK was added to betslip and then category was changed to TOT
            // after clicking BOK button on racecard we want to remove TOT bet from betslip
            // instead of adding another bet on the same runner with BOK category
            if (betCategory === BetCategory.BOOKIE) {
                betCategories.push(BetCategory.TOTE)
            } else if (betCategory === BetCategory.TOTE) {
                betCategories.push(BetCategory.BOOKIE)
            }
            if (market === BetType.WIN) {
                return (
                    getBetRunnerId(bet) === idRunner &&
                    betType === bet.market &&
                    market === BetType.WIN &&
                    betCategories.includes(bet.category)
                )
            }
            return getBetRunnerId(bet) === idRunner && betType === bet.market
        })

        // check if runner is in stable and if other runner from stable is in betslip
        // if yes - remove bet
        if (race.stables?.length) {
            const stable = race.stables.find((stable) =>
                stable.includes(idRunner)
            )
            if (stable) {
                const idxStableInBetslip: number[] = []
                stable.forEach((id: string) => {
                    const idx = this.bets().findIndex(
                        (bet) =>
                            getBetRunnerId(bet) === id &&
                            bet.category === betCategory &&
                            betType === bet.market
                    )
                    if (idx > -1) {
                        idxStableInBetslip.push(idx)
                    }
                })

                if (idxStableInBetslip.length) {
                    return idxStableInBetslip.forEach((idx) => {
                        this.removeBet(idx)
                    })
                }
            }
        }

        if (indexOfBetInBetslip > -1) {
            return this.removeBet(indexOfBetInBetslip) // bet on runner in betslip - remove bet
        }

        const betCurrency =
            betCategory === BetCategory.TOTE
                ? race.tote_currency
                : userStoreService.currency()

        const eachWay = isEachWay(market, race.bet_types, betCategory)

        let categoryMulti = betCategory
        const categoriesForThisbetType = race.bet_types.filter((betTypeObj) => {
            return betTypeObj.bet_type === betType
        })[0].categories
        // if bet category is TOTE check if there is other category and switch multiple
        if (
            betCategory === BetCategory.TOTE &&
            categoriesForThisbetType.length > 1
        ) {
            categoryMulti = categoriesForThisbetType.filter(
                (x) => x !== BetCategory.TOTE
            )[0]
        }

        // if multiple bookie is not allow for this race try to use fixed
        if (
            !race.multiples_bok &&
            race.multiples_fxd &&
            betCategory === BetCategory.BOOKIE &&
            categoriesForThisbetType.some((x) => x === BetCategory.FIXED)
        ) {
            categoryMulti = BetCategory.FIXED
        }

        // if multiple FIXED is not allow for this race try to use BOOKIE
        if (
            !race.multiples_fxd &&
            race.multiples_bok &&
            betCategory === BetCategory.FIXED &&
            categoriesForThisbetType.some((x) => x === BetCategory.BOOKIE)
        ) {
            categoryMulti = BetCategory.BOOKIE
        }

        const multi = RULES[market]?.multi || 1

        if (runner && market) {
            betslipStoreService.addBet({
                category: betCategory,
                category_multi: categoryMulti,
                id_freebet: null,
                currency: betCurrency,
                is_ante_post: race.is_ante_post,
                in_multiples: isInMultiple,
                is_each_way: eachWay,
                market,
                num_bets: multi,
                exchange_rate: appStoreService.exchangeRates()[
                    race.tote_currency || userStoreService.currency()
                ][userStoreService.currency()]
                    ? exchange(
                          1,
                          betCurrency,
                          userStoreService.currency(),
                          appStoreService.exchangeRates(),
                          true
                      )
                    : 1,
                selections: generateSelectionsForSingleBet(
                    race.id,
                    runner,
                    market
                ),
                special_type: betSpecialType,
                stamp: getTimestamp(),
                uid: generateId(),
                unit_stake: 0,
            })
        }

        // Add bet effect when bet is added to betslip
        renderAddToBetslipAnimation(idRunner)
    }

    addExoticBet(
        betCategory: OBR.Betting.BetCategory,
        betType: OBR.Betting.BetType,
        currency: OBR.User.Currency,
        selections: OBR.Betting.BetSelection[],
        unitStake: number,
        numberOfBets: number,
        uid: string
    ) {
        betslipStoreService.addBet({
            category: betCategory,
            category_multi: betCategory,
            id_freebet: null,
            currency: currency,
            in_multiples: false,
            is_each_way: false,
            market: betType,
            num_bets: numberOfBets,
            selections: [...selections],
            special_type: BetSpecialType.EXOTIC,
            stamp: getTimestamp(),
            uid,
            unit_stake: unitStake,
            is_ante_post: false,
            exchange_rate:
                appStoreService.exchangeRates()[currency][
                    userStoreService.currency()
                ] || 1,
        })
    }

    updateBetFeatures(
        data: {
            isEachWay: boolean
            stake: number | null
            category: OBR.Betting.BetCategory
            idFreebet?: string | null
            currency?: OBR.User.Currency
            idRms?: number
        },
        uid: string
    ) {
        const { bet, index } = getBetAndIndexByUid(this.bets(), uid)
        if (!bet) return

        const runner = raceStoreService.runnerByCache(getBetRunnerId(bet))
        const race = raceStoreService.raceByCache(getBetRaceId(bet))
        const isEachWay = isEachWayAvailable(
            race?.bet_types,
            bet.market,
            data.category
        )
            ? data.isEachWay
            : false

        if (runner?.scratched) return

        const newBet = Object.assign({}, bet, {
            is_each_way: isEachWay,
            num_bets: isEachWay ? 2 : RULES[bet.market]?.multi || bet.num_bets,
            category: data.category,
            unit_stake: data.stake,
        })

        if (data.currency) {
            newBet.currency = data.currency
        }
        if (data.idFreebet) {
            newBet.id_freebet = data.idFreebet
        } else {
            newBet.id_freebet = null
        }
        // any changes in bet will remove id_rms except when idRms is passed
        if (!data.idRms) {
            delete newBet.id_rms
        }
        this.updateBet(newBet, index)
    }

    addMultiples(data: {
        isEachWay: boolean
        stake: number
        system: OBR.Betting.System
        baseBetsCount: number
        amount: number
        userCurrency: OBR.User.Currency
    }) {
        useBetslip().onAddMultples(prepareMultiple(data))
    }

    updateMultiples(data: {
        isEachWay: boolean
        stake: number
        system: OBR.Betting.System
        baseBetsCount: number
        amount: number
        userCurrency: OBR.User.Currency
    }) {
        useBetslip().onUpdateMultples(prepareMultiple(data))
    }

    removeMultiples(payload: {
        system: OBR.Betting.System
        baseBetsCount: number
    }) {
        useBetslip().onRemoveMultiples(payload)
    }

    removeAllMultiples() {
        useBetslip().onUnsetAllMultiples()
    }

    // MUTATIONS
    setHasChange(status: boolean) {
        return useBetslip().onSetHasChange(status)
    }

    setHasUnmatchedOdds(status: boolean) {
        return useBetslip().onSetHasUnmatchedOdds(status)
    }

    setCurrencyHasChanged(status: boolean) {
        return useBetslip().onSetCurrencyHasChanged(status)
    }

    /**
     * Mark bet as rms was accepted
     */
    updateRmsAccepted(idRms: number) {
        return useBetslip().onAcceptRms(idRms)
    }

    /**
     * Mark all bets as rms was accepted
     */
    acceptAllRms() {
        useBetslip().onAcceptAllRms()
    }
    open() {
        return useUser().bets_open
    }

    updateBetOdds(idRace: string, idRunner: string, betIdx: number) {
        const bet = useBetslip().bets[betIdx]
        const race = raceStoreService.raceByCache(idRace)
        const runner = raceStoreService.runnerByCache(idRunner)
        if (race && runner) {
            const selections = generateSelectionsForSingleBet(
                race.id,
                runner,
                bet.market
            )
            this.updateBet(Object.assign(bet, { selections }), betIdx)
        }
    }

    /**
     * Pick bet section in betslip store
     */

    /**
     * place pick bet
     * @param betPick
     * @returns {Promise<OBR.Betting.BetslipResponse>}
     */
    addPickbets(
        betType: OBR.Betting.BetType,
        betCategory: OBR.Betting.BetCategory,
        unitStake: number,
        currency: OBR.User.Currency,
        selections: OBR.Betting.BetSelection[],
        numBets: number,
        idRms: number | undefined
    ) {
        return useBetslip()
            .onSavePickBet({
                category: betCategory,
                category_multi: betCategory,
                id_freebet: null,
                currency: currency,
                in_multiples: false,
                is_each_way: false,
                market: betType,
                num_bets: numBets,
                selections,
                special_type: BetSpecialType.NORMAL,
                stamp: getTimestamp(),
                uid: generateId(),
                unit_stake: unitStake,
                id_rms: idRms,
                is_ante_post: false,
                exchange_rate:
                    appStoreService.exchangeRates()[currency][
                        userStoreService.currency()
                    ] || 1,
            })
            .then((res: OBR.Betting.BetslipResponse) => {
                if (res.type === 'success') {
                    // update balance
                    userStoreService.getBalance()
                    emitter.emit(EMITTER_BET_PLACED)
                }
                useBetslip().onRmoveAllRmsData()
                useBetslip().onSetHasUnmatchedOdds(false)
                return res
            })
    }

    activePickBetID(): string {
        return useBetslip().activePickBet
    }

    tempActivePickBetID(): string {
        return useBetslip().tempActivePickBet
    }
    /**
     * Get active pick bet
     * @returns {OBR.Betting.PickBetSelection}
     */
    activePickBet(): OBR.Betting.PickBetSelection | undefined {
        const activePickBetID = this.activePickBetID()

        if (!activePickBetID) {
            return undefined
        }

        return this.pickBetById(activePickBetID)
    }

    /**
     * Get pickbets
     * @returns {OBR.Common.Object<OBR.Betting.PickBetSelection>}
     */
    pickBets(): OBR.Common.Object<OBR.Betting.PickBetSelection> {
        return useBetslip().pickBets
    }

    /**
     * Get pickbets by pickbet id
     * @param id
     * @returns {OBR.Betting.PickBetSelection}
     */
    pickBetById(id: string): OBR.Betting.PickBetSelection {
        return useBetslip().pickBets[id]
    }

    /**
     * Get leg by pickbet id and leg id
     * @param pickBetId
     * @param legId
     * @returns {OBR.Betting.PickBetLeg}
     */
    pickBetLegById(
        pickBetId: string,
        legId: string
    ): OBR.Betting.PickBetLeg | undefined {
        return useBetslip().pickBets[pickBetId]?.[legId]
    }

    /**
     * Get pick bet marks by pick bet id
     * @param pickBetId
     * @returns {number[]}
     */
    pickBetMarksById(pickBetId: string): number[] {
        return getPickMarks(this.pickBetById(pickBetId))
    }

    /**
     * Get pick bet number of bets by pick bet id
     * @param pickBetId
     * @returns {number}
     */
    pickBetNumberOfBetsById(pickBetId: string): number {
        return getPickBetCount(this.pickBetMarksById(pickBetId))
    }

    /**
     * Get selected runners length by leg id
     * @param pickBetId
     * @param legId
     * @returns {number}
     */
    pickBetLegSelectedRunnersLength(pickBetId: string, legId: string): number {
        return Object.values(
            useBetslip().pickBets[pickBetId]?.[legId]?.runners || {}
        ).filter((runner) => !runner.excluded).length
    }

    /**
     * Get selected runners length by pickbet id
     * @param pickBetId
     * @returns {number}
     */
    pickBetSelectedRunnersLength(pickBetId: string): number {
        return Object.entries(useBetslip().pickBets[pickBetId] || {}).reduce(
            (acc, [_, leg]) => {
                return acc + Object.keys(leg.runners || {}).length
            },
            0
        )
    }

    /**
     * Check if there's pick bet
     * If has pick bet, check if there's leg with selected runners
     * @returns {boolean}
     */
    hasActivePickBet(id: string): boolean {
        return this.pickBetSelectedRunnersLength(id) > 0
    }

    setActivePickBet(pickBetId: string) {
        return useBetslip().onSetActivePickBet(pickBetId)
    }

    /**
     * Set Pick Bet
     * @param pickBetId
     * @param pickBetSelection
     */
    setPickBet({
        id,
        pickBetSelection,
    }: {
        id: string
        pickBetSelection: OBR.Betting.PickBetSelection
    }) {
        return useBetslip().onSetPickBet({ id, pickBetSelection })
    }

    /**
     * Update Pick Bet Leg
     * @param pickBetId
     * @param legId
     * @param legLabel
     * @param runner
     * @returns {Promise<void>}
     */
    updatePickBetLeg({
        idPickBet,
        idLeg,
        runner,
        force_update,
    }: {
        idPickBet: string
        idLeg: string
        runner: OBR.Betting.PickBetLegRunner
        force_update?: boolean
    }) {
        useBetslip().onUpdatePickBetLeg({
            idPickBet,
            idLeg,
            runner,
            force_update,
        })
    }

    /**
     * Set excluded runner for pick bet leg
     * @param pickBetId
     * @param legId
     * @param runnerId
     */
    setPickBetLegExcludedRunner({
        idPickBet,
        idLeg,
        runnerNumber,
    }: {
        idPickBet: string
        idLeg: string
        runnerNumber: number
    }) {
        return useBetslip().onSetPickBetLegExcludedRunner({
            idPickBet,
            idLeg,
            runnerNumber,
        })
    }

    /**
     * Remove scratch runner from pick bet leg
     * @param pickBetId
     * @param idRunners
     */
    removePickBetLegScratchRunner({
        idPickBet,
        idRunners,
    }: {
        idPickBet: string
        idRunners: string[]
    }) {
        return useBetslip().onRemovePickBetLegScratchRunner({
            idPickBet,
            idRunners,
        })
    }

    /**
     * Reset Pick Bet Legs
     * @param pickBetId
     * @returns {Promise<void>}
     */
    resetPickBetLegs(pickBetId: string) {
        return useBetslip().onUnsetAllPickBetLegs({ pickBetId })
    }

    resetPickBetLeg(pickBetId: string, legId: string) {
        return useBetslip().onUnsetPickBetLeg({ pickBetId, legId })
    }

    updateMultiBetType(newMultiBetType: OBR.Betting.BetType) {
        return useBetslip().onUpdateMultiBetType(newMultiBetType)
    }

    dialog() {
        return useBetslip().dialog
    }

    updatedDialog(payload: {
        isVisible: boolean
        status?: 'primary' | 'action'
        title?: string
        message?: string
        confirm?: string
        cancel?: string
        actionConfirm?: () => void
        actionCancel?: () => void
    }) {
        return useBetslip().onUpdateDialog(payload)
    }
    updateBetsCurrency(currency: OBR.User.Currency) {
        const betsToChange = this.bets().filter((bet) => {
            return (
                bet.currency !== currency && bet.category !== BetCategory.TOTE
            )
        })

        if (betsToChange.length > 0) {
            this.setCurrencyHasChanged(true)
            betsToChange.forEach((bet) => {
                this.updateBetCurrency(bet, currency)
            })
        }
    }
    updateBetCurrency(bet: OBR.Betting.Bet, currency: OBR.User.Currency) {
        const { index } = getBetAndIndexByUid(this.bets(), bet.uid)
        this.updateBet(Object.assign(bet, { currency }), index)
    }
    removeBetMultipleCategoryByUid(
        newMultiBetCategory: OBR.Betting.BetCategory,
        uid: string
    ) {
        const { index } = getBetAndIndexByUid(this.bets(), uid)
        return useBetslip().onUpdateMultiBetCategory(newMultiBetCategory, index)
    }

    changeBetMultipleCategoryByIndex(
        newMultiBetCategory: OBR.Betting.BetCategory,
        index: number
    ) {
        return useBetslip().onUpdateMultiBetCategory(newMultiBetCategory, index)
    }

    getEditedExoticBetUid(): string | null {
        return useBetslip().editedExoticBetUid
    }

    setEditedExoticBetUid(uid?: string) {
        useBetslip().onSetEditedExoticBetUid(uid)
    }
}

export const betslipStoreService = new BetslipStoreService()
