import { OddsFormat, ODDS_FRACTIONAL } from '@obr-core/config/settings'
import { BetCategory } from '@obr-core/config/betting'
import { FixedOddsStatus } from '@obr-core/config/race'

export function greatestCommonDivisor(a: number, b: number): number {
    while (b !== 0) {
        const temp = b
        b = a % b
        a = temp
    }
    return Math.abs(a)
}

export function formatOdds(
    odds: number,
    format: OddsFormat = OddsFormat.DECIMAL,
    withPaddingZeros: boolean = false
): string | number {
    if (format === OddsFormat.FRACTIONAL) {
        // check if odds have predefined rounded fractional value
        if (ODDS_FRACTIONAL[odds]) {
            return ODDS_FRACTIONAL[odds]
        }

        let denum = 1
        // asure if odds number has not more then 2 decimal digits
        let roundedOdds = Number((odds - 1).toFixed(2))

        // convert decimal to fractional with like 1.25 => 4/100
        while (roundedOdds % 1 !== 0) {
            denum *= 10
            roundedOdds = Number((roundedOdds * 10).toFixed(2))
        }

        // find the greatest common divisor for the numerator and denominator
        // e.g: for 4/100 is 4
        const gdcNumb = greatestCommonDivisor(roundedOdds, denum)

        // devide divide the numerator and denominator
        return `${roundedOdds / gdcNumb}/${denum / gdcNumb}`
    }

    if (format === OddsFormat.BASE10) {
        odds = Math.floor(odds * 10)
    }

    // default format is DECIMAL
    return withPaddingZeros ? odds.toFixed(2) : odds
}

/**
 * Get bet category from fixed odds status
 */
export function getOddsButtonBetCategory(
    fixedOddsStatus: OBR.Race.FixedOddsStatus
) {
    if (fixedOddsStatus !== FixedOddsStatus.NOT_AVAILABLE) {
        return BetCategory.FIXED
    }

    return BetCategory.BOOKIE
}
