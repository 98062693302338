import { computed, ref, watch } from 'vue'

import {
    appStoreService,
    betslipStoreService,
    eventStoreService,
    raceStoreService,
    userStoreService,
} from '@obr-core/services/store'
import { i18n } from '@obr-core/i18n/i18n'
import {
    getPickBetRunnersId,
    getPickSelections,
} from '@obr-core/helpers/betslip.helpers'
import { BetCategory } from '@obr-core/config/betting'
import { ModalBettingFinalType } from '@obr-core/config/modal'
import { useBetslipDialog } from '@obr-ui/components/Betslip/composable/useBetslipDialog'
import {
    formatPickBetLeg,
    formatPickBetSelection,
} from '@obr-ui/components/PickBets/helpers'
import { RaceStatus } from '@obr-core/config/race'

export function usePickBets() {
    const { toggleBetslipDialog } = useBetslipDialog()
    const stake = ref<undefined | number>(undefined)
    const resetSelection = ref(false)
    const disableSubmit = ref(true)

    /**
     * Modal window data related
     */
    const submitBetMsg = ref('')
    const submitBetPublicIds = ref<string[]>([])
    const modalWindowType = ref(ModalBettingFinalType.DEFAULT_ERROR)
    const rmsDetails = ref({
        numBets: 0,
        idRms: 0,
        stake: 0,
    })

    const pickBetCart = computed(() => {
        return eventStoreService.pickBetsCacheById(
            betslipStoreService.activePickBetID()
        )
    })
    const eventCart = computed(() => {
        return eventStoreService.eventByCache(
            pickBetCart.value?.races[0].id_event || ''
        )
    })
    const hasRaceStarted = computed(() => {
        return pickBetCart.value?.races?.some((race) => {
            const raceStatusCache =
                raceStoreService.raceByCache(race.id_race)?.status ||
                race.status
            return raceStatusCache !== RaceStatus.OPEN
        })
    })
    const isDisabled = computed<boolean>(
        () => !pickBetCart.value?.pick_bet_open || hasRaceStarted.value || false
    )
    const pickMarks = computed(() => {
        return betslipStoreService.pickBetMarksById(pickBetCart.value?.id || '')
    })
    const selectionValid = computed(() => {
        if (!pickBetCart.value?.races?.length) {
            return false
        }
        return pickMarks.value.length >= pickBetCart.value.races.length
    })
    const numBets = computed(() => {
        return selectionValid.value
            ? betslipStoreService.pickBetNumberOfBetsById(
                  pickBetCart.value?.id || ''
              )
            : 0
    })

    const pickBetSelection = computed<OBR.Betting.PickBetSelection>(() =>
        betslipStoreService.pickBetById(pickBetCart.value?.id || '')
    )

    const betCurrency = computed(() => {
        if (
            eventCart.value?.tote_currency &&
            pickBetCart.value?.bet_category !== BetCategory.BOOKIE
        ) {
            return eventCart.value.tote_currency as OBR.User.Currency
        }
        return userStoreService.currency()
    })
    const runners = computed(() => {
        return pickBetSelection.value
            ? getPickBetRunnersId(pickBetSelection.value).map((runner) =>
                  raceStoreService.runnerByCache(runner)
              )
            : []
    })
    const scratchedRunners = computed(() =>
        runners.value.filter((runner) => runner?.scratched)
    )

    watch(
        numBets,
        (value, oldValue) => {
            if (value > 0) {
                disableSubmit.value = false
            }
            if (oldValue && oldValue > 0 && value === 0) {
                disableSubmit.value = true
            }
        },
        { immediate: true }
    )

    function onSubmitBet({
        pickBetID,
        stake,
        numBets,
        idRms,
    }: {
        pickBetID: string
        stake: number
        numBets: number
        idRms?: number
    }) {
        if (!userStoreService.isLoggedIn()) {
            userStoreService.authRequired()
            return
        }

        const pickBet = eventStoreService.pickBetsCacheById(pickBetID)

        if (pickBet) {
            disableSubmit.value = true
            const event = pickBet?.races[0].id_event
                ? eventStoreService.eventByCache(pickBet?.races[0].id_event)
                : null
            const pickbetSelection = betslipStoreService.pickBetById(pickBet.id)
            const betCurrency = () => {
                if (
                    event &&
                    event?.tote_currency &&
                    pickBet?.bet_category !== BetCategory.BOOKIE
                ) {
                    return event.tote_currency as OBR.User.Currency
                }
                return userStoreService.currency()
            }

            appStoreService.setLoadingOverlay(true)
            betslipStoreService
                .addPickbets(
                    pickBet.bet_type,
                    pickBet.bet_category,
                    stake,
                    betCurrency(),
                    getPickSelections(pickbetSelection),
                    numBets,
                    idRms
                )

                .then((res) => {
                    modalWindowType.value = ModalBettingFinalType.SUCCESS
                    submitBetPublicIds.value = res.bets.map(
                        (bet) => bet.public_id
                    )
                    submitBetMsg.value = i18n.global.t(
                        'betting.label_bet_placed',
                        {
                            idBetslip: res.bets
                                .map((bet) => bet.public_id)
                                .join(', '),
                        }
                    )
                })
                .catch((error) => {
                    if (error.new_unit_stake && error.id_rms) {
                        rmsDetails.value.numBets = numBets
                        rmsDetails.value.stake = error.new_unit_stake
                        rmsDetails.value.idRms = error.id_rms
                    }
                    modalWindowType.value =
                        error.modalType || ModalBettingFinalType.DEFAULT_ERROR
                    submitBetMsg.value = error.message
                })
                .finally(() => {
                    appStoreService.setLoadingOverlay(false)
                    disableSubmit.value = false
                })
        }
    }

    function onUpdatePickbetSelection(
        payload: OBR.UI.Components.PickBets.UpdateBetslipPickBetPayload
    ): void {
        const activePickBetID = betslipStoreService.activePickBetID()
        const pickBet = eventStoreService.pickBetsCacheById(payload.id_pickbet)
        if (!pickBet) {
            return
        }
        const simpleBets = betslipStoreService.bets()
        if (simpleBets.length) {
            toggleBetslipDialog('single', () =>
                onUpdatePickbetSelection(payload)
            )
            return
        }

        if (activePickBetID && activePickBetID !== payload.id_pickbet) {
            const activePickBet =
                betslipStoreService.hasActivePickBet(activePickBetID)

            // If previous pick bet has selection we must show popup to confirm if want change pickbet
            if (activePickBet) {
                toggleBetslipDialog('pick', () =>
                    onUpdatePickbetSelection(payload)
                )
                return
            } else {
                // clean pickbet store
                betslipStoreService.resetPickBetLegs(activePickBetID)

                // set initial pick bet state
                betslipStoreService.setPickBet({
                    id: payload.id_pickbet,
                    pickBetSelection: formatPickBetSelection(pickBet),
                })
            }
        } else if (!activePickBetID) {
            // set initial pick bet state
            betslipStoreService.setPickBet({
                id: payload.id_pickbet,
                pickBetSelection: formatPickBetSelection(pickBet),
            })
        }

        betslipStoreService.updatePickBetLeg({
            ...formatPickBetLeg({
                id_pickbet: payload.id_pickbet,
                runner: payload.runner,
                race: payload.race,
            }),
            force_update: payload.force_update,
        })
    }

    function cleanModal() {
        submitBetMsg.value = ''
    }

    function resetPickbetSelection(pickBetID: string) {
        betslipStoreService.resetPickBetLegs(pickBetID)
    }

    function onCloseModal(pickBetID: string) {
        if (
            [
                ModalBettingFinalType.SUCCESS,
                ModalBettingFinalType.RMS_ERROR,
            ].includes(modalWindowType.value)
        ) {
            // reset bet selection
            resetPickbetSelection(pickBetID)
            // resete unit stakes
            resetSelection.value = !resetSelection.value
        }
        cleanModal()
    }

    function onRetainSelections() {
        cleanModal()
    }

    async function sendSMSResult({
        publicIds,
        mobile,
        retain,
        idPickBet,
    }: {
        publicIds: string[]
        mobile: string
        retain: boolean
        idPickBet: string
    }) {
        // send sms result
        betslipStoreService.smsResult(publicIds, mobile).then(() => {
            if (retain) {
                onRetainSelections()
            } else {
                onCloseModal(idPickBet)
            }
        })
    }

    function onRmsAccepted(pickBetID: string) {
        onSubmitBet({
            pickBetID,
            ...rmsDetails.value,
        })
        submitBetMsg.value = ''
    }

    return {
        pickBetCart,
        eventCart,
        stake,
        isDisabled,
        selectionValid,
        numBets,
        betCurrency,
        disableSubmit,
        submitBetMsg,
        submitBetPublicIds,
        modalWindowType,
        scratchedRunners,
        onSubmitBet,
        onUpdatePickbetSelection,
        onCloseModal,
        onRetainSelections,
        onRmsAccepted,
        sendSMSResult,
    }
}
