import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { POST_MESSAGE_ID_BET_SUCCESS } from '@obr-core/config/post-messages'
import { postMessageService } from '@obr-core/services/PostMessageService'
import {
    onValidateBetslipError,
    handleBetslipError,
} from '@obr-core/errors/betslip.errors'
import { ErrorService } from '@obr-core/services/ErrorService'

export class BetslipResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/betting/betslip`
    private readonly apiPathValidate: string = `${HTTP_REST_V2}/betting/betslip/validate`
    private readonly apiPathCombination: string = `${HTTP_REST_V2}/exotics`

    /**
     * Validate betslip
     */
    public async validate(betslip: OBR.Betting.BetslipPayload) {
        try {
            const res = await http.post(this.apiPathValidate, {
                betslip,
            })

            const errors: OBR.Betting.BetslipValidationError[] = res?.data
                ?.error?.data?.errors
                ? res.data.error.data.errors
                : []
            onValidateBetslipError(errors)
        } catch (error: any) {
            ErrorService.getInstance().preventDefault(error)
        }
    }

    /**
     * Save betslip
     */
    public async save(
        betslip: Pick<OBR.Store.BetslipState, 'bets' | 'multiples'>,
        isMultiples: boolean = false
    ): Promise<OBR.Betting.BetslipResponse> {
        try {
            const res = await http.post(this.apiPath, {
                betslip,
            })

            if (res.data.type === 'success') {
                postMessageService.send(POST_MESSAGE_ID_BET_SUCCESS, {})
            }
            return res.data
        } catch (error: any) {
            ErrorService.getInstance().preventDefault(error)
            throw handleBetslipError(error, isMultiples)
        }
    }

    /**
     * Betslip SMS Result Notification
     */
    public async smsResult(publicIds: string[], mobile: string) {
        try {
            const res = await http.post(`${this.apiPath}/sms_results`, {
                publicIds,
                mobile,
            })

            return res
        } catch (error: any) {
            ErrorService.getInstance().preventDefault(error)
        }
    }

    /**
     * Betslip Get highest combination
     */
    public async getHighestCombination(
        payload: OBR.Betting.HighestCombinationPayload
    ): Promise<OBR.Betting.HighestCombinationResponse> {
        try {
            const res = await http.post(
                `${this.apiPathCombination}/highest`,
                payload
            )
            return res.data
        } catch (error: any) {
            throw ErrorService.getInstance().preventDefault(error)
        }
    }

    /**
     * Betslip Get highest combination
     */
    public async getAllCombination(
        payload: OBR.Betting.AllCombinationsPayload
    ): Promise<OBR.Betting.AllCombinationResponse> {
        try {
            const res = await http.post(
                `${this.apiPathCombination}/combinations`,
                payload
            )
            return res.data
        } catch (error: any) {
            throw ErrorService.getInstance().preventDefault(error)
        }
    }
}
