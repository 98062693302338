import {
    Currency,
    Jurisdiction,
    OddsFormat,
    SETTINGS_BRITISH_ISLES,
    SETTINGS_DEFAULT_CURRENCY,
    SETTINGS_DEFAULT_JURISDICTION,
    SETTINGS_DEFAULT_ODDS_FORMAT,
    SETTINGS_DEFAULT_ODDS_FORMAT_BRITISH,
    SETTINGS_DEFAULT_TLD,
    SETTINGS_DEFAULT_UNIT_SYSTEM,
    SETTINGS_DEFAULT_UNIT_SYSTEM_BRITISH,
    SETTINGS_DEFAULT_ENABLED_VIRTUAL,
    SETTINGS_DEFAULT_DISABLED_VIRTUAL_COUNTRIES,
    SETTINGS_JURISDICTIONS,
    UnitSystem,
    DIAL_CODES_OBJECT,
    SETTINGS_DEFAULT_DIAL,
    SETTINGS_DEFAULT_COUNTRIES_ORDER,
} from '@obr-core/config/settings'
import {
    TIMEZONE_CHILD_TO_PARENT,
    TIMEZONE_DEFAULT,
} from '@obr-ui/components/Settings/config'
import { getTld } from '@obr-core/utils/utils'
import { userStoreService } from '@obr-core/services/store'

/**
 * Returns odds format based on country
 * @param country
 */
export const getDefaultOddsFormat = (
    country: OBR.Settings.Country
): OddsFormat => {
    return SETTINGS_BRITISH_ISLES.includes(country)
        ? SETTINGS_DEFAULT_ODDS_FORMAT_BRITISH
        : SETTINGS_DEFAULT_ODDS_FORMAT
}

/**
 * Returns unit system based on country
 * @param country
 */
export const getDefaultUnitSystem = (
    country: OBR.Settings.Country
): UnitSystem => {
    return SETTINGS_BRITISH_ISLES.includes(country)
        ? SETTINGS_DEFAULT_UNIT_SYSTEM_BRITISH
        : SETTINGS_DEFAULT_UNIT_SYSTEM
}

/**
 * Returns website's TLD
 * Defaults to config TLD
 * @param hostname
 */
export const getWebsiteTLD = (hostname?: string): OBR.Settings.TLD => {
    const websiteTLD = getTld(hostname || location.hostname)

    return websiteTLD || SETTINGS_DEFAULT_TLD
}

/**
 * Returns license jurisdiction based on IP country
 * Defaults to config
 * @param ipCountry
 */
export const getLicenseJurisdiction = (
    ipCountry?: OBR.Settings.Country
): Jurisdiction => {
    const country = ipCountry as Jurisdiction
    if (SETTINGS_JURISDICTIONS.includes(country)) {
        return country
    }
    return SETTINGS_DEFAULT_JURISDICTION
}

/**
 * Returns if virtuals are enabled
 * @param jurisdiction
 * @param ipCountry
 */
export const isVirtualEnabled = (
    jurisdiction: Jurisdiction | null,
    ipCountry: OBR.Settings.Country
): boolean => {
    if (!SETTINGS_DEFAULT_ENABLED_VIRTUAL) {
        return false
    } else if (jurisdiction) {
        return !SETTINGS_DEFAULT_DISABLED_VIRTUAL_COUNTRIES.includes(
            jurisdiction
        )
    } else if (ipCountry) {
        return ipCountry.includes(ipCountry)
    }
    return true
}

/**
 * scompose phone in dial and number
 */
export function scomposePhoneNumber(phone: string): {
    dialcode: string
    number: string
} {
    let dialcode = userStoreService.country() || SETTINGS_DEFAULT_DIAL
    let number = ''
    if (phone) {
        let _phone = phone.replace(/\s/g, '') // Remove spaces
        _phone = _phone.replace(/^0+/, '') // Remove leading zeros
        _phone = _phone.replace(/^\+/, '') // Remove leading '+'

        // check if phone start with dialcode
        for (const code in DIAL_CODES_OBJECT) {
            if (_phone.startsWith(`${DIAL_CODES_OBJECT[code]}`)) {
                dialcode = code
                number = _phone.substr(`${DIAL_CODES_OBJECT[code]}`.length)
                break
            }
        }
    }
    return {
        dialcode,
        number,
    }
}

/**
 * compare 2 phone numbers with dialcode
 */
export function comparePhoneNumbers(phone1: string, phone2: string): boolean {
    const { dialcode: dialcode1, number: number1 } = scomposePhoneNumber(phone1)
    const { dialcode: dialcode2, number: number2 } = scomposePhoneNumber(phone2)
    return dialcode1 === dialcode2 && number1 === number2
}

/**
 * Validates the preferred stakes array.
 * If the preferred stakes array is valid, it is returned.
 * Otherwise, the default stakes array is returned.
 * e.g. used to validate the preferred stakes array in me request and from local storage
 */
export function validatePreferredStakes(
    preferredStakes: any,
    defaultStakes: number[]
): number[] {
    // The PreferredStakes length has to be equal or greater then the length of the default stakes array
    const preferredStakesLength = defaultStakes.length

    // Check if the preferred stakes array is valid - is an array of numbers with correct length
    if (
        Array.isArray(preferredStakes) &&
        preferredStakes.length >= preferredStakesLength &&
        !preferredStakes.some((item) => typeof item !== 'number')
    ) {
        return preferredStakes
    }
    // otherwise return the default stakes array
    return defaultStakes
}

/**
 * Validates the order of countries array.
 * e.g. used to validate the preferred stakes array in me request and from local storage
 */
export function validateCountryOrder(countriesOrder: any): string[] {
    return Array.isArray(countriesOrder)
        ? countriesOrder
        : SETTINGS_DEFAULT_COUNTRIES_ORDER
}

/**
 * Initializes the timezone
 * first check if TZ stored in the storage is valid
 * if not check if system time zone is on our list - if true return parent timezone
 * if not return default time zone
 */
export function initTimezone(tzFromStorage: string, tzSystem: string) {
    // check if saved
    if (TIMEZONE_CHILD_TO_PARENT[tzFromStorage]) {
        return TIMEZONE_CHILD_TO_PARENT[tzFromStorage]
    } else if (TIMEZONE_CHILD_TO_PARENT[tzSystem]) {
        TIMEZONE_CHILD_TO_PARENT[tzSystem]
    }

    return TIMEZONE_DEFAULT
}
