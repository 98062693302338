/**
 * Race Type
 */
export const enum RaceType {
    GALLOP = 'G',
    TROT = 'T',
    JUMP = 'J',
    GREYHOUND = 'D',
    SHOW_JUMPING = 'S',
}

/**
 * Race Status
 */
export const enum RaceStatus {
    OPEN = 'OPN',
    STARTED = 'STR',
    TEMPORARY_RESULTS = 'TMP',
    FINAL = 'FNL',
    CANCELLED = 'CNC',
    SUSPENDED = 'SPD',
    REVIEW = 'RVW',
    NULL = 'NULL',
}

/**
 * Race Status Class
 */
export const enum RaceStatusClass {
    OPEN = 'open',
    CANCELLED = 'cancelled',
    FINAL = 'finished',
    GOING = 'going',
    NULL = 'null',
}

/**
 * Track Surface
 */
export const enum TrackSurface {
    SAND = 'DRT',
    TURF = 'TRF',
    POLYTRACK = 'EQT',
    SNOW = 'SNW',
}

/**
 * TRACK GOING
 */
export enum TrackGoing {
    NONE = 0,
    HARD = 1,
    FIRM = 2,
    GOOD_TO_FIRM = 2.5,
    GOOD = 3,
    GOOD_TO_SOFT = 3.5,
    SOFT = 4,
    SOFT_TO_HEAVY = 4.5,
    HEAVY = 5,
    VERY_HEAVY = 6,
}

/**
 * TRACK GOING
 */
export enum StartType {
    NONE = '',
    BAND = 'B',
    CAR = 'C',
    STANDING = 'S',
}
/**
 * Fixed Odds Status
 */
export const enum FixedOddsStatus {
    ON = 'ON',
    OFF = 'OFF',
    NOT_AVAILABLE = 'NAV',
    SUSPENDED = 'SPD',
}

/**
 * Meeting Domain
 */
export const enum MeetingDomain {
    VIRTUAL = 'virtual',
    NON_VIRTUAL = 'nonVirtual',
}

/**
 * Jackpot types
 */
export const enum JackpotType {
    GUARANTEED_PAYOUT = 'GUP',
    JACKPOT = 'JPT',
    STAKES_BONUS = 'SBN',
    SUPER = 'SUP',
    WINNING_BONUS = 'WBN',
    // special case for DE country
    SUPERFECTA = 'SFC',
}

/**
 * Promotion types
 */
export const enum PromotionType {
    CUSTOM = 'custom',
    MONEY_BACK = 'money-back',
    ENHANCED_PLACE = 'enhanced-place',
    FREEBET = 'freebet',
}

/**
 * TOT gateway types
 */
export const enum ToteGateway {
    COL = 'COL',
    DTX = 'DTX',
    BMK = 'BMK',
    EGT = 'EGT',
    XTC = 'XTC',
    'XTC-FR' = 'XTC-FR',
    'XTC-INT' = 'XTC-INT',
}

/**
 * Best odds guarantee countries
 */
export const BOG_COUNTRIES = ['GB', 'IE', 'ZA', 'AE']
